module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"gtagConfig":{},"pluginConfig":{"respectDNT":false},"trackingIds":["G-HTQCQ7051S"]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Inter var"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#fff","description":"Hi, I am Lorenz Nimmervoll, a highly skilled Cloud Engineer from Austria. With years of experience in the field, I specialize in creating high-performance and scalable applications and systems on the AWS platform. My expertise lies in designing and implementing cloud-based solutions that meet the unique needs of businesses across various industries. I am passionate about leveraging the latest technologies and best practices to deliver innovative solutions that drive business growth and success. My commitment to excellence, attention to detail, and strong problem-solving skills make me a valuable asset to any team. Let's work together to take your business to new heights.","display":"standalone","icon":"src/images/atws_solid.svg","name":"Lorenz Nimmervoll","short_name":"Lorenz Nimmervoll","start_url":"/","theme_color":"#fff","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"49ec0a057bdbd848ffa307e3eab3f012"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
